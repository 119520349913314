import React from 'react'
import { ApiResponseProps } from '../../context/TrackingFormContext'

interface Props {
  isPrintView?: boolean
  apiResponse: ApiResponseProps
}

const TrackingFormResults: React.FunctionComponent<Props> = ({
  isPrintView,
  apiResponse,
}) => {
  return (
    <>
      <div className="md:col-span-5">
        <h1 className="font-ttHovesBold text-2xl leading-bigTitle mb-8 sm:text-3xl md:text-4xl md:pt-2 lg:text-5xl lg:mb-16">
          Tracking Information
        </h1>
        {apiResponse.nppronumber && (
          <h2
            className={`text-lg mb-4 lg:text-2xl ${
              isPrintView ? 'mb-6' : 'lg:mb-0'
            }`}
          >
            NPT Pro Number: {apiResponse.nppronumber}
          </h2>
        )}
      </div>
      <div
        className={`border border-black rounded p-4 lg:px-0 lg:flex lg:col-span-3 ${
          isPrintView ? 'mb-8' : 'lg:justify-center'
        }`}
      >
        <div className={`${isPrintView ? 'md:p-4' : 'md:py-6'} self-center `}>
          {apiResponse.status && (
            <h3 className="font-ttHovesMedium text-xl mb-4 sm:text-2xl lg:text-4xl">
              Status: <span className="uppercase">{apiResponse.status}</span>
            </h3>
          )}
          {apiResponse.deldt && (
            <p className="font-ttHovesMedium text-base mb-3 md:text-xl lg:text-2xl">
              Delivery Date: {apiResponse.deldt}
            </p>
          )}
          {apiResponse.boldt && (
            <p className="font-ttHovesMedium text-base md:text-xl lg:text-2xl">
              Pickup Date: {apiResponse.boldt}
            </p>
          )}
        </div>
      </div>
      <div
        className={`col-span-2 px-4 pt-8 lg:p-0 lg:flex ${
          isPrintView ? '' : 'lg:justify-center items-center'
        }`}
      >
        <ul className="text-base md:text-lg lg:text-xl">
          {apiResponse.bolno && (
            <li>
              <p className="mb-1">
                BOL #: <span className="uppercase">{apiResponse.bolno}</span>
              </p>
            </li>
          )}
          {apiResponse.shipper && (
            <li>
              <p className="mb-1">
                Shipper #:{' '}
                <span className="uppercase">{apiResponse.shipper}</span>
              </p>
            </li>
          )}
          {apiResponse.pono && (
            <li>
              <p className="mb-1">
                PO#: <span className="uppercase">{apiResponse.pono}</span>
              </p>
            </li>
          )}
          {apiResponse.origin && (
            <li>
              <p className="mb-1">
                Origin: <span className="uppercase">{apiResponse.origin}</span>
              </p>
            </li>
          )}
          {apiResponse.dest && (
            <li>
              <p className="mb-1">
                Destination:{' '}
                <span className="uppercase">{apiResponse.dest}</span>
              </p>
            </li>
          )}
        </ul>
      </div>
    </>
  )
}
export default TrackingFormResults
