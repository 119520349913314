import React from 'react'
import VideoOverlay from '../shared/VideoOverlay'
import PlayButton from './PlayButton'
import disableScroll from 'disable-scroll'
interface VideoProps {
  bgVideo: string
  title?: string
  subTitle?: string
  youtubeEmbedUrl: string
  showVideoModal: boolean
  setVideoIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  placeHolderImage: string
  videoPlaceholderImageAlt: string
}

const Video: React.FC<VideoProps> = ({
  bgVideo,
  showVideoModal,
  title,
  subTitle,
  setVideoIsVisible,
  youtubeEmbedUrl,
  placeHolderImage,
  videoPlaceholderImageAlt,
}) => {
  return (
    <section className="mx-auto max-w-screen-2xl">
      <div className="relative flex flex-col items-center w-full px-4 md:px-0 md:mx-auto md:w-[87%] lg:w-[82%]">
        {title ? (
          <div
            className="text-center mb-4 max-w-4xl text-3xl font-ttHovesDemiBold text-green-100 text-bigTitleMobile leading-bigTitle sm:text-[2.25rem] md:text-[3rem] xl:text-[4.5rem] xl:tracking-[-0.005em] xl:whitespace-nowrap"
            dangerouslySetInnerHTML={{ __html: String(title) }}
          />
        ) : null}
        {subTitle ? (
          <div
            className="mb-6 text-center text-green-100 text-nptBase tracking-[0.005em] xl:font-ttHovesMedium"
            dangerouslySetInnerHTML={{ __html: String(subTitle) }}
          />
        ) : null}

        <div className="relative w-full h-full overflow-hidden rounded-md shadow-lg group">
          <video
            src={bgVideo}
            className="w-full h-full"
            style={{
              objectFit: 'cover',
            }}
            autoPlay={true}
            playsInline={true}
            muted={true}
            loop={true}
          />

          <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full transition-opacity opacity-0 group-hover:opacity-100">
            <PlayButton
              additionalClassnames="cursor-pointer"
              handleClick={() => {
                disableScroll.on()
                setVideoIsVisible(true)
              }}
            />
          </div>
        </div>

        {showVideoModal && youtubeEmbedUrl && (
          <VideoOverlay
            youtubeEmbedUrl={youtubeEmbedUrl}
            showVideoModal={showVideoModal}
            videoIsVisible={setVideoIsVisible}
          />
        )}
      </div>
    </section>
  )
}

export default Video
