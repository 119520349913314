import Link from 'next/link'
import React from 'react'
import useBreakpoint from 'use-breakpoint'
import Markdown from 'react-markdown'
import {
  ButtonFontSize,
  greenButtonStyles,
  smallPaddingStyles,
} from '../forms/elements/SubmitButton'

interface ApiAnnouncementProps {
  apiEyebrow: string
  apiButtonText: string
  apiDescriptionFirst: string
  apiDescriptionSecond: string
  apiGridList: [
    {
      title: string
      icon: string
    }
  ]
}
const BREAKPOINTS = { S: 375, M: 768, L: 1024, XL: 1280 }
const ApiAnnouncement: React.FunctionComponent<ApiAnnouncementProps> = ({
  apiEyebrow,
  apiButtonText,
  apiDescriptionFirst,
  apiDescriptionSecond,
  apiGridList,
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'XL')

  return (
    <section className="relative mx-6 lg:py-6 lg:mx-[15%] text-green-100">
      <p className="text-red-200 text-nptBase tracking-[.005em] leading-bigTitle font-ttHovesMedium mb-6 mx-auto 2xl:w-[1010px]">
        {apiEyebrow}
      </p>
      <div className=" flex flex-col  lg:flex xl:gap-x-[7px] lg:flex-row relative mx-auto 2xl:w-fit">
        <div className="lg:w-[790px]">
          <p
            id="trigger-bottom"
            className="font-ttHovesDemiBold w-[55%] min-w-[325px] md:w-full whitespace-normal lg:whitespace-nowrap l leading-bigTitle text-[2.25rem] xl:text-[3rem] "
          >
            Experience the benefits of modern
          </p>
          <div className="flex flex-col pt-[61px] font-sans lg:pt-[1.4rem] gap-y-[1.3rem] text-nptBase">
            <p className="leading-relaxed tracking-[.005em] lg:w-[550px] xl:w-[86%]">
              {apiDescriptionFirst}
            </p>
            <p className="leading-relaxed tracking-[.005em] lg:w-[550px] xl:w-[86%]">
              {apiDescriptionSecond}
            </p>
            <Link
              href="https://api.nopk.com/v1/swagger"
              className={`${greenButtonStyles} ${smallPaddingStyles} ${ButtonFontSize.small}`}
              title="Join the Team"
            >
              {apiButtonText}
            </Link>
            <div className="grid pt-[50px] gap-y-4 lg:grid-cols-[322px_332px] lg:grid-rows-2 lg:gap-y-12 lg:gap-x-[1.5rem] w-[90%] lg:w-[97%] xl:ml-[10px]">
              {apiGridList.map((item, i) => (
                <div
                  key={`list_${i}`}
                  className="flex w-full md:items-center lg:items-start gap-x-3"
                >
                  <div className="flex items-center justify-center h-10 overflow-hidden md:w-6">
                    <img
                      className="min-h-[18px] min-w-[21px] md:w-auto"
                      src={item.icon}
                      alt="API Icons"
                    />
                  </div>
                  <Markdown className="text-nptBase leading-relaxed w-[85%] md:w-full api-bullet">
                    {item.title}
                  </Markdown>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          data-aos-anchor="#trigger-center"
          data-aos={`${breakpoint == 'XL' ? 'scroll-npk' : ''}`}
          id="trigger-center"
          className="w-[205px] xl:w-[218px] h-[43px] xl:h-auto top-[85px] md:top-0 md:left-[595px] xl:left-[565px] left-[0px] overflow-clip xl:overflow-visible gap-y-2 flex flex-col absolute xl:static xl:mr-auto order-first xl:order-last col-start-2 row-span-1 font-ttHovesBold leading-bigTitle text-[2.25rem] tracking-[0.03em] xl:text-[3rem]"
        >
          <div
            data-aos-anchor="#trigger-bottom"
            data-aos={`${breakpoint != 'XL' ? 'mobile-track' : ''}`}
            className={`flex flex-col xl:contents `}
          >
            <div
              data-aos-anchor="#trigger-center"
              data-aos={`${breakpoint != 'XL' ? '' : 'scroll-service'}`}
              className="w-full h-[58px] text-red-200"
            >
              service.
            </div>
            <div
              data-aos-anchor="#trigger-center"
              data-aos={`${breakpoint != 'XL' ? '' : 'scroll-quotes'}`}
              className="w-full h-[58px] text-red-200"
            >
              quotes.
            </div>
            <div
              data-aos-anchor="#trigger-center"
              data-aos={`${breakpoint != 'XL' ? '' : 'scroll-tracking'}`}
              className="w-full h-[58px] text-red-200"
            >
              tracking.
            </div>
            <div
              data-aos-anchor="#trigger-center"
              data-aos={`${breakpoint != 'XL' ? '' : 'scroll-pickups'}`}
              className="w-full h-[58px] text-red-200"
            >
              pickups.
            </div>
            <div
              data-aos-anchor="#trigger-center"
              data-aos={`${breakpoint != 'XL' ? '' : 'scroll-logistics'}`}
              className="w-full h-[58px] text-red-200"
            >
              logistics.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ApiAnnouncement
