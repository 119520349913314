import React from 'react'
import Link from 'next/link'
import TrackingFormResults from '../forms/results/TrackingFormResults'
import { ApiResponseProps } from '../context/TrackingFormContext'
import {
  darkGreenButtonStyles,
  largePaddingStyles,
} from '../forms/elements/SubmitButton'
import SecondaryButton from '../shared/SecondaryButton'
import Modal from '../shared/Modal'

interface Props {
  isTrackingModalOpen: boolean
  setIsTrackingModalOpen: (x?: any) => void
  serializedFormData: string
  apiResponse: ApiResponseProps
}

const TrackingResultsModal: React.FunctionComponent<Props> = ({
  isTrackingModalOpen,
  setIsTrackingModalOpen,
  apiResponse,
  serializedFormData,
}) => {
  return (
    <Modal
      maxWidth="max-w-4xl"
      setIsModalOpen={setIsTrackingModalOpen}
      isModalOpen={isTrackingModalOpen}
    >
      <div className="w-full p-4 pt-10 md:py-6 md:px-11 lg:py-20 lg:grid lg:grid-cols-5 lg:gap-4">
        <TrackingFormResults apiResponse={apiResponse} />
        <div className="col-span-5">
          <div className="flex justify-end py-10 mt-16 text-right md:pb-0 lg:mt-12">
            <Link
              href={`/print/tracking-results?${serializedFormData}`}
              target="_blank"
              rel="noreferrer"
              className={`${darkGreenButtonStyles} flex items-center rounded px-8 pt-2.5 pb-2 md:px-11 text-[17px]`}
            >
              Print
            </Link>
            <SecondaryButton
              onClick={() => {
                setIsTrackingModalOpen(!isTrackingModalOpen)
              }}
              text="Close"
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default TrackingResultsModal
