import React, { useState, useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import { ErrorBelowInputField } from './elements/Errors'
import { InputText } from './elements/Inputs'
import SubmitButton, { ButtonFontSize } from './elements/SubmitButton'
import { VerifyZipDataFormContext } from '../context/VerifyZipDataFormContext'
import { retryAxiosInstance, globalConfig } from '@/types/retry'

const VerifyZipCodeForm: React.FunctionComponent = () => {
  const router = useRouter()
  const [originZip, setOriginZip] = useState('')
  const [destZip, setDestZip] = useState('')
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState(false)
  const { verifyZipDataFormApiResponse, setVerifyZipDataFormApiResponse } =
    useContext(VerifyZipDataFormContext)
  const regex = /^[0-9]*$/
  const allDigits = (zip: string) => {
    return regex.test(zip)
  }
  const formData = {
    ozip: originZip,
    dzip: destZip,
    weight1: '50',
  }

  const submitForm = async () => {
    {
      if (
        !allDigits(originZip) ||
        !allDigits(destZip) ||
        originZip.length == 0 ||
        originZip.length == 0 ||
        destZip.length !== 5 ||
        originZip.length !== 5
      ) {
        const newVerifyZipDataFormApiResponse = {
          errozp:
            !allDigits(originZip) || !originZip || originZip.length != 5
              ? 'Y'
              : 'N',
          errdzp:
            !allDigits(destZip) || !destZip || destZip.length != 5 ? 'Y' : 'N',
          ...((!destZip || destZip.length != 5 || !allDigits(destZip)) && {
            emsgdzp: 'Please enter a valid 5 digit zip code.',
          }),
          ...((!originZip ||
            originZip.length != 5 ||
            !allDigits(originZip)) && {
            emsgozp: 'Please enter a valid 5 digit zip code.',
          }),
        }
        setVerifyZipDataFormApiResponse(newVerifyZipDataFormApiResponse)
        return
      }
    }
    const serializedData = new URLSearchParams(formData).toString()
    try {
      setLoading(true)
      const response = await retryAxiosInstance.post(
        `/api/verify-zip-code`,
        serializedData,
        {
          ...globalConfig,
          timeout: 5000, // Set a timeout of 5 seconds
        }
      )

      const verifyZipResponse = response.data
      setVerifyZipDataFormApiResponse(verifyZipResponse)
      if (
        verifyZipResponse.errozp === 'N' &&
        verifyZipResponse.errdzp === 'N'
      ) {
        router.push({
          pathname: '/rate-estimate',
          query: {
            originZip: originZip,
            destZip: destZip,
          },
        })
      } else {
        setLoading(false)
        if (
          verifyZipDataFormApiResponse.errozp === 'Y' &&
          verifyZipDataFormApiResponse.errdzp === 'Y'
        ) {
          throw new Error(
            `IBMi failed to fetch at api/verify-zip-code: ${response.data}.`
          )
        } else {
          setVerifyZipDataFormApiResponse(verifyZipResponse)
        }
      }
    } catch (error: any) {
      setLoading(false)
      setFormError(true)
      console.error('api/verify-zip-codeFailed after 3 attempts')
      console.log(error)
    }
  }

  return (
    <section>
      <form
        onSubmit={(event: any) => {
          event.preventDefault()
          submitForm()
        }}
      >
        <div className="mt-3 mb-4 md:mt-6 md:mb-6 md:text-nptBase lg:mt-7 lg:mb-[1.865rem]">
          <InputText
            labelText="Destination"
            type="text"
            placeholder="Zip Code"
            value={destZip}
            characterMax={5}
            onChange={(event: any) => {
              setDestZip(event.target.value)
              setVerifyZipDataFormApiResponse({})
            }}
          />

          {verifyZipDataFormApiResponse.errdzp && (
            <ErrorBelowInputField
              errorMessage={verifyZipDataFormApiResponse.emsgdzp}
            />
          )}
        </div>

        <div className="mb-4 md:mb-8 lg:">
          <InputText
            labelText="Origin"
            type="text"
            placeholder="Zip Code"
            value={originZip}
            characterMax={5}
            onChange={(event: any) => {
              setOriginZip(event.target.value)
              setVerifyZipDataFormApiResponse({})
            }}
          />

          {verifyZipDataFormApiResponse.errozp && (
            <ErrorBelowInputField
              errorMessage={verifyZipDataFormApiResponse.emsgozp}
            />
          )}
        </div>

        <SubmitButton
          onClick={() => {
            return false
          }}
          text={loading ? 'Validating Zipcodes...' : 'Get Rate Estimate'}
          isDisabled={originZip && destZip ? (loading ? true : false) : true}
          fontSize={ButtonFontSize.small}
        />
        {formError && (
          <ErrorBelowInputField errorMessage="Please try again later." />
        )}
      </form>
    </section>
  )
}

export default VerifyZipCodeForm
