import React from 'react'

interface HeadingOneProps {
  additionalClasses?: string
  children: React.ReactNode
  color?: string
  fontWeight?: string
  fontSize?: string
  id?: string
}

const HeadingOne: React.FC<HeadingOneProps> = ({
  children,
  additionalClasses = '',
  color = 'text-green-classic',
  fontWeight = 'font-ttHovesMedium',
  fontSize = 'text-2xl md:text-4xl',
  id,
}) => {
  return (
    <h1
      id={id}
      className={`${color} ${fontWeight} ${fontSize} ${additionalClasses} leading-10`}
    >
      {children}
    </h1>
  )
}

export default HeadingOne
