import React from 'react'
import Link from 'next/link'
import {
  ButtonFontSize,
  darkGreenButtonStyles,
  largePaddingStyles,
} from '../forms/elements/SubmitButton'

interface Props {
  subTitle: string
  title: string
  text: string
  imageSrc: string
  buttonText: string
}

const Careers: React.FunctionComponent<Props> = ({
  subTitle,
  title,
  text,
  imageSrc,
  buttonText,
}) => {
  return (
    <section className="content-center gap-5 mx-auto max-w-screen-2xl md:px-8 md:grid md:grid-cols-2 xl:px-[80px] xl:grid-cols-[52%_48%]">
      <img
        className="w-full"
        alt="Careers at North Park Transportation"
        src={imageSrc}
      />
      <div className="px-4 pt-4 mt-6 md:p-0 md:m-0 md:flex md:item-stretch lg:justify-self-end">
        <div className="md:self-center md:pl-8 xl:pl-32 xl:self-start xl:mt-[165px]">
          <p className="mb-4 text-nptCRed text-nptBase tracking-[.0125em] font-ttHovesMedium lg:mb-6">
            {subTitle}
          </p>
          <h1 className="max-w-4xl mb-4 text-4xl text-green-100 leading-bigTitle font-ttHovesDemiBold lg:text-bigTitleMobile xl:text-[3rem] lg:mb-6">
            {title}
          </h1>
          <p className="mb-6 text-green-100 leading-bigTitle text-nptBase xl:leading-[1.6] xl:mr-6 xl:tracking-[0.01em]">
            {text}
          </p>
          <Link
            href="/join-the-team"
            className={`${darkGreenButtonStyles} ${largePaddingStyles} ${ButtonFontSize.small}`}
            title="Join the Team"
          >
            {buttonText}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Careers
