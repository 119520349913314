import React, { useEffect, useState } from 'react'
import { attributes } from '../../content/index.md'
import Image from 'next/image'
import HeadingOne from '../portal/ui/typography/HeadingOne'
import SubmitButton, { ButtonFontSize } from '../forms/elements/SubmitButton'
import router from 'next/router'

function extractFirstSentence(text: string) {
  const sentences = text.split('. ')
  const heroBoldTitle = sentences.shift()
  const heroBody = sentences.join('. ')

  return { heroBoldTitle, heroBody }
}

const ServiceMapGraphic: React.FunctionComponent = () => {
  const {
    serviceMapHero,
    serviceMapText,
    serviceMapImage1x,
    serviceMapImageMobile,
  } = attributes
  const { heroBoldTitle, heroBody } = extractFirstSentence(serviceMapText)

  const [windowWidth, setWindowWidth] = useState(1440)
  const [imageSrc, setImageSrc] = useState(serviceMapImage1x)

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (windowWidth < 768) {
      setImageSrc(serviceMapImageMobile)
    } else {
      setImageSrc(serviceMapImage1x)
    }
  }, [windowWidth])

  return (
    <div className="relative h-full mx-auto overflow-hidden">
      <Image
        className="z-0 object-cover w-full"
        width={1440}
        height={768}
        src={imageSrc}
        alt="service mappings"
      />

      <div className="top-0 left-0 flex w-full h-full md:absolute">
        <div className="md:flex w-0 md:w-[58%]" />
        <div
          className="relative p-[36px] pb-0 m-auto md:w-full bg-white bg-opacity-0 md:ml-[3%] md:mr-[5%]  md:flex-1 h-full justify-center items-center md:items-center md:p-2
          md:mt-[3%] mdLg:top-[10%] mdXl:top-[8%] lg:top-[8%] lgMd:top-[15%]
          xlMd:pr-[4%] 2xl:pr-[6%] 2xl':mr-[7%] 2xl':ml-[5%]"
        >
          {' '}
          <HeadingOne
            fontWeight="font-ttHovesDemiBold"
            fontSize="text-[32px] lg:text-[40px] xl:text-[48px]"
            additionalClasses="leading-none mb-6 xsm:pr-[25%] xsmMd:pr-[0%]  tracking-custom mdXl:pr-8 lg:pr-0 lgMd:pr-8"
          >
            {serviceMapHero}
          </HeadingOne>
          <p className="font-ttHovesRegular text-[14px] lg:text-[17px] text-green-classic leading-relaxed lg:leading-[160%] tracking-[-0.020em] lg:tracking-[0.085px]">
            <span className="font-ttHovesDemiBold">{heroBoldTitle}. </span>
            {heroBody}
          </p>
          <div className="mt-6">
            <SubmitButton
              onClick={() => router.push('/service-area')}
              text="Check Transit Times"
              isDisabled={false}
              fontSize={ButtonFontSize.small}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ServiceMapGraphic
