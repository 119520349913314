import Head from 'next/head'
import Script from 'next/script'
import React from 'react'
import { attributes } from '../content/global-seo-meta.md'

interface Props {
  title: string
  description: string
}

const MetaContent: React.FunctionComponent<Props> = ({
  title,
  description,
}) => {
  const { ogImage } = attributes

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <title>{`North Park Transportation: ${title}`}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content="https://nopk.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`North Park Transportation: ${title}`}
        />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
        <meta itemProp="name" content={`North Park Transportation: ${title}`} />
        <meta itemProp="description" content={description} />
        <meta itemProp="image" content={ogImage} />
        <link rel="preload" href="/fonts/TTHovesItalic/font.woff" as="font" />
        <link rel="preload" href="/fonts/TTHovesItalic/font.woff2" as="font" />
        <link rel="preload" href="/fonts/TTHovesRegular/font.woff" as="font" />
        <link rel="preload" href="/fonts/TTHovesRegular/font.woff2" as="font" />
        <link rel="preload" href="/fonts/TTHovesBold/font.woff" as="font" />
        <link rel="preload" href="/fonts/TTHovesBold/font.woff2" as="font" />
        <link
          rel="preload"
          href="/fonts/TTHovesBoldItalic/font.woff"
          as="font"
        />
        <link
          rel="preload"
          href="/fonts/TTHovesBoldItalic/font.woff2"
          as="font"
        />
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css"
          rel="stylesheet"
        />
      </Head>
      <Script id="structured-data" async type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'LocalBusiness',
          name: 'North Park Transportation',
          image: ogImage,
          '@id': 'https://nopk.com',
          url: 'https://nopk.com',
          telephone: '303-295-0300',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '5150 Columbine St',
            addressLocality: 'Denver',
            addressRegion: 'CO',
            postalCode: '80216',
            addressCountry: 'US',
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: 39.7899671,
            longitude: -104.9555869,
          },
        })}
      </Script>
      <Script
        async
        id="netlify-identity-widget"
        src="https://identity.netlify.com/v1/netlify-identity-widget.js"
      />
    </>
  )
}

export default MetaContent
