import MetaContent from '../components/MetaContent'
import HomeHero from '../components/home/HomeHero'
import Service from '../components/home/Service'
import Testimonial from '../components/home/Testimonial'
import Careers from '../components/home/Careers'
import { attributes } from '../content/index.md'
import ServiceMap from '../components/home/ServiceMapGraphic'
import API from '@/components/home/ApiAnnouncement'
import NowServicing from '@/components/home/NowServicing'
import { useState } from 'react'
import Video from '@/components/careers/Video'

export default function Home() {
  const [videoIsVisible, setVideoIsVisible] = useState(false)
  const {
    metaTitle,
    metaDescription,
    heroTitle,
    heroSubTitle,
    apiEyebrow,
    apiButtonText,
    apiDescriptionFirst,
    apiDescriptionSecond,
    apiGridList,
    serviceTitle,
    serviceSubTitle,
    serviceText,
    serviceList,
    serviceImage,
    testimonialTitle,
    testimonialText,
    testimonialButtonText,
    testimonialImage,
    testimonialEmbedVideo,
    careersSubTitle,
    careersTitle,
    careersText,
    careersImage,
    careersButtonText,
  } = attributes

  return (
    <>
      <MetaContent title={metaTitle} description={metaDescription} />

      <main className="flex-grow overflow-hidden">
        <HomeHero title={heroTitle} subTitle={heroSubTitle} />

        <div className="space-y-[6.25rem] mb-[6.25rem] lg:space-y-[12.5rem] lg:mb-[12.5rem]">
          <ServiceMap />
          <Video
            title={testimonialTitle}
            subTitle={testimonialText}
            bgVideo="https://player.vimeo.com/external/573171877.hd.mp4?s=3e79838788579802e6d8ba0a484c1740d296ffaf&profile_id=174"
            youtubeEmbedUrl={testimonialEmbedVideo}
            showVideoModal={videoIsVisible}
            setVideoIsVisible={setVideoIsVisible}
            placeHolderImage={testimonialImage}
            videoPlaceholderImageAlt="This is a placeholder image"
          />
          <API
            apiEyebrow={apiEyebrow}
            apiButtonText={apiButtonText}
            apiDescriptionFirst={apiDescriptionFirst}
            apiDescriptionSecond={apiDescriptionSecond}
            apiGridList={apiGridList}
          />
          <Service
            title={serviceTitle}
            subTitle={serviceSubTitle}
            text={serviceText}
            list={serviceList}
            imageSrc={serviceImage}
          />
          <Careers
            subTitle={careersSubTitle}
            title={careersTitle}
            text={careersText}
            imageSrc={careersImage}
            buttonText={careersButtonText}
          />
        </div>
      </main>
    </>
  )
}
