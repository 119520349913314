import React from 'react'

interface Props {
  errorMessage: string
}

export const ErrorBelowInputField: React.FunctionComponent<Props> = ({
  errorMessage,
}) => {
  return <p className="mt-2 text-xs text-nptBRed">{errorMessage}</p>
}
