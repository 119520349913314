import React, { useState } from 'react'
import VerifyZipCodeForm from '../forms/VerifyZipCodeForm'
import TrackingForm from '../forms/TrackingForm'

interface Props {
  title: string
  subTitle: string
}

const HomeHero: React.FunctionComponent<Props> = ({ title, subTitle }) => {
  const [showDefaultForm, formIsDefault] = useState<boolean>(true)

  return (
    <section className="m-auto max-w-screen-2xl px-4 md:px-[3.9rem] mb-20">
      <div className="lg:grid lg:grid-cols-5">
        <h1 className="relative max-w-4xl font-ttHovesBold text-green-100 text-bigTitleMobile leading-bigTitle my-16 -ml-1 md:text-6xl md:my-20 md:pr-10 lg:pr-0 lg:text-[6rem] lg:leading-[6rem] lg:my-[105px] lg:col-span-3 lg:-tracking-[0.005em]">
          {title}
          {subTitle && (
            <span className="absolute mx-2 my-1 text-sm text-nptBRed font-ttHovesRegular md:text-nptBase md:m-3">
              {subTitle}
            </span>
          )}
        </h1>
        <aside className="grid md:col-span-2 justify-items-end">
          <div className="w-full self-start shadow-sm border border-gray-200 rounded mb-5 p-4 md:mb-[2.5rem] md:p-[2.5rem] lg:pl-[28px] lg:pr-[26px] lg:mt-[6rem] lg:max-w-md">
            <ul className="grid grid-cols-2 text-sm pt-1.5 md:pt-3.5 lg:pt-0 md:text-nptBase xl:text-xl justify-between">
              <li
                className={`${
                  showDefaultForm
                    ? 'text-nptBRed border-nptBRed border-b-2'
                    : 'text-green-100 border-green-classic border-b'
                }`}
              >
                <button
                  type="button"
                  className="focus:outline-none tracking-[.02em] whitespace-nowrap"
                  aria-expanded="false"
                  onClick={() => formIsDefault(true)}
                >
                  Track Your Shipment
                </button>
              </li>
              <li
                className={`lg:text-center ${
                  !showDefaultForm
                    ? 'text-nptBRed border-nptBRed border-b-2'
                    : 'text-green-100 border-green-classic border-b'
                }`}
              >
                <button
                  type="button"
                  className="focus:outline-none tracking-[.03em] md:ml-[3%] lg:ml-0"
                  aria-expanded="false"
                  onClick={() => formIsDefault(false)}
                >
                  Get Rate Estimate
                </button>
              </li>
            </ul>
            {!showDefaultForm && <VerifyZipCodeForm />}
            {showDefaultForm && <TrackingForm />}
          </div>
        </aside>
      </div>
    </section>
  )
}

export default HomeHero
