export const formatRequestBodyDate = (range: number) => {
  const today = new Date()
  const beginDate = new Date(today)
  beginDate.setDate(today.getDate() - range)

  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)

  return {
    beginDate: Number(
      String(beginDate.toISOString().split('T')[0]).replaceAll('-', '')
    ),
    endDate: Number(
      String(tomorrow.toISOString().split('T')[0]).replaceAll('-', '')
    ),
  }
}
