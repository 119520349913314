import React from 'react'

interface PlayButtonProps {
  additionalClassnames?: string
  handleClick: (x?: any) => void
}

const PlayButton: React.FC<PlayButtonProps> = ({
  additionalClassnames,
  handleClick,
}) => {
  return (
    <svg
      onClick={() => handleClick()}
      className={additionalClassnames}
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="104" height="104" rx="52" fill="#D1D5DB" fillOpacity="0.6" />
      <path d="M64 52L46 62.3923V41.6077L64 52Z" fill="white" />
    </svg>
  )
}

export default PlayButton
