import React from 'react'

interface Props {
  onClick: (x?: any) => void
  text: string
}

const SecondaryButton: React.FunctionComponent<Props> = ({ onClick, text }) => {
  return (
    <button
      className="text-green-100 text-sm font-ttHovesMedium px-8 ml-6 pt-2.5 pb-2 text-center whitespace-nowrap rounded border border-green-classic duration-300 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none md:text-nptBase md:pl-11 md:pr-11"
      onClick={() => onClick()}
    >
      {text}
    </button>
  )
}

export default SecondaryButton
