import React from 'react'

interface Props {
  title: string
  subTitle: string
  text: string
  list: [
    {
      title: string
    }
  ]
  imageSrc: string
}

const Service: React.FunctionComponent<Props> = ({
  title,
  subTitle,
  text,
  list,
  imageSrc,
}) => {
  return (
    <section className="mx-auto max-w-screen-2xl md:px-11 lg:py-12 xl:px-20">
      <div className="grid gap-y-10 gap-x-7 lg:grid-cols-[146fr_167fr]">
        <div className="relative order-last lg:order-first">
          <div className="px-4 md:px-0 lg:absolute lg:top-[50%] lg:-translate-y-1/2 xl:top-[45%]">
            <hgroup className="mb-4 lg:mb-6">
              <h1 className="text-4xl text-green-100 leading-bigTitle font-ttHovesDemiBold lg:text-bigTitleMobile xl:text-[3rem]">
                {title}
              </h1>
              {subTitle ? (
                <h2 className="text-4xl text-green-100 leading-bigTitle font-ttHovesMedium lg:text-bigTitleMobile xl:text-[3rem]">
                  {subTitle}
                </h2>
              ) : null}
            </hgroup>
            <div className="text-green-100 text-nptBase">
              <p className="mb-6 lg:w-[80%] text-nptBase tracking-[.005em]">
                {text}
              </p>
              <ul>
                {list.map((item, i) => (
                  <li
                    key={`list_${i}`}
                    className="underline uppercase font-ttHovesDemiBold text-nptBase decoration-1 underline-offset-[3px] lg:text-[1.25rem] leading-[1.8] tracking-[-.005em] mb-6 last:mb-0"
                  >
                    {item.title}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          alt={`North Park Transportation Co - ${title}`}
          className="order-first lg:order-last"
          src={imageSrc}
          sizes="(min-width: 1536px) 1536px, (min-width: 1024px) 100vw, 50vw"
          loading="lazy"
        />
      </div>
    </section>
  )
}

export default Service
