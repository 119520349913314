export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID

declare global {
  interface Window {
    gtag: any
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = async (url: string, pageTitle?: string) => {
  try {
    window?.gtag('config', GA_TRACKING_ID, {
      page_path: url,
      page_title: pageTitle || '',
    })
  } catch (error) {
    console.log('GA Error', error)
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = async ({ action, category, label, value }: any) => {
  try {
    if (typeof window !== 'undefined' && window.gtag)
      window?.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
      })
  } catch (error) {
    console.log('GA Error', error)
  }
}
