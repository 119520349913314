import React from 'react'
import disableScroll from 'disable-scroll'

interface Props {
  youtubeEmbedUrl: string
  showVideoModal: boolean
  videoIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const VideoOverlay: React.FunctionComponent<Props> = ({
  youtubeEmbedUrl,
  showVideoModal,
  videoIsVisible,
}) => {
  return (
    <section className="fixed top-0 left-0 z-50 flex w-screen h-screen bg-black bg-opacity-80 item-stretch lg:p-5">
      <div className="relative w-full m-auto lg:w-3/4">
        <img
          alt=""
          className="w-full"
          role="presentation"
          src="/images/video-placeholder.png"
        />
        <iframe
          className="absolute top-0 w-full h-full m-auto"
          src={youtubeEmbedUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture"
          allowFullScreen
        />
      </div>
      <button
        className="absolute right-4 top-4"
        onClick={() => {
          disableScroll.off()
          videoIsVisible(!showVideoModal)
        }}
      >
        <span className="sr-only">Close video overlay</span>
        <img
          src="/images/mobile-close-icon.svg"
          alt="Close video overlay"
          className="w-10 h-10"
        />
      </button>
    </section>
  )
}

export default VideoOverlay
